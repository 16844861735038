import React, { useCallback, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, tabletLarge } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import MapSelector from "@/game-val/AgentMapSelector.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import {
  getGuideThumbnail,
  getParamsStrForAgent,
  getSrc,
  updateParams,
} from "@/game-val/guides-utils.mjs";
import { diffFallback } from "@/game-val/MapMenuListItem.jsx";
import { getAgentImage } from "@/game-val/utils.mjs";
import VideoModal from "@/game-val/VideoModal.jsx";
import BlitzChevronRight from "@/inline-assets/chevron-right.svg";
import maxOccuranceBy from "@/util/max-occurance-by.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  background: var(--shade8);
  --difficulty-easy: #65d23f;
  --difficulty-medium: #eba928;
  --difficulty-subscriber: var(--primary);
  --agent-color: var(--agent-color, var(--shade0));
`;

const MapFrame = styled("div")`
  position: relative;
  height: 0;
  margin: var(--sp-6) 0;
  padding-bottom: 100%;

  .map-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MapDot = styled("div")`
  --diff-color: ${({ $difficulty }) => `var(--difficulty-${$difficulty})`};
  top: ${({ $positionY }) => `${$positionY}%`};
  left: ${({ $positionX }) => `${$positionX}%`};

  position: absolute;
  height: var(--sp-1_5);
  width: var(--sp-1_5);
  transform: translate(-50%, -50%);
  background: var(--agent-color);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 var(--sp-1) calc(5 * var(--sp-px)) var(--shade9);
  transition: var(--transition);

  &:hover {
    transform: translate(-50%, -50%) scale(1.3);
    z-index: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &::before {
    right: calc(-1 * var(--sp-1_5));
    bottom: calc(-1 * var(--sp-1_5));
    height: calc(5 * var(--sp-px));
    width: calc(5 * var(--sp-px));
    background: var(--diff-color);
    box-shadow: 0 0 0 var(--sp-0_5) var(--shade9);
    z-index: 1;
  }
  &::after {
    top: calc(-3 * var(--sp-px));
    left: calc(-3 * var(--sp-px));
    right: calc(-3 * var(--sp-px));
    bottom: calc(-3 * var(--sp-px));
    border: var(--sp-px) solid var(--agent-color);
  }
`;

const TipsLink = styled("a")`
  display: flex;
  align-items: center;

  &:hover {
    svg {
      transform: translateX(calc(5 * var(--sp-px)));
    }
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .agent-headshot {
    width: var(--sp-8);
    margin-right: var(--sp-4);
    border-radius: 50%;
    border: var(--sp-0_5) solid var(--agent-color);
  }
  .subtitle {
    color: var(--shade2);
  }
  svg {
    font-size: var(--sp-6);
    margin-left: var(--sp-4);
    transition: var(--transition);
  }
`;

const DotTooltip = styled("div")`
  margin: -17px -17px -16px !important;

  img {
    width: calc(var(--sp-px) * 100);
    display: block;
    border-radius: var(--br);
  }
  .difficulty {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: calc(-3 * var(--sp-px));
    right: calc(-3 * var(--sp-px));
    background: var(--shade9);
    padding: var(--sp-0_5) var(--sp-1);
    border-radius: var(--br-sm);

    .diff-dot {
      height: calc(5 * var(--sp-px));
      width: calc(5 * var(--sp-px));
      margin-right: var(--sp-1);
      border-radius: 50%;
      background: var(--diff-color);
    }
  }
`;

const CardInner = styled("div")`
  padding: var(--sp-6);

  ${tabletLarge} {
    padding: var(--sp-4);
  }

  ${mobile} {
    padding: var(--sp-2);
  }
`;

const Dot = ({ positionX, positionY, video, difficulty, onClick }) => {
  const { t } = useTranslation();

  const tooltip = (
    <DotTooltip>
      <img src={getGuideThumbnail(video)} />
      {difficulty && (
        <div
          style={{ "--diff-color": `var(--difficulty-${difficulty})` }}
          className="difficulty"
        >
          <div className="diff-dot" />
          <span className="overline">
            {t(`common:${difficulty}`, diffFallback[difficulty])}
          </span>
        </div>
      )}
    </DotTooltip>
  );
  const tooltipHTML = ReactDOMServer.renderToStaticMarkup(tooltip);

  return (
    <MapDot
      $difficulty={difficulty}
      $positionX={positionX}
      $positionY={positionY}
      data-tip={tooltipHTML}
      onClick={onClick}
    />
  );
};

export default function AgentMapGuides({ agent }) {
  const {
    val: {
      guides,
      meta: {
        maps: { list },
      },
    },
    settings: {
      valorant: { muted },
    },
  } = useSnapshot(readState);
  const [src, setSrc] = useState("");
  const { t } = useTranslation();

  const { searchParams, currentPath } = useRoute();

  const urlParams = getParamsStrForAgent(searchParams, agent.key);
  const guidesList = guides[btoa(urlParams)]?.list;

  const { map } = Object.fromEntries(urlParams);
  const maxOccuredMap = maxOccuranceBy(guidesList, "map");
  const selectedMap =
    list &&
    (list.find((m) => m.key === map) ||
      list.find((m) => maxOccuredMap === m.key));

  const onMapChange = useCallback(
    (map) => {
      updateParams({
        searchParams,
        currentPath,
        map,
      });
    },
    [currentPath, searchParams],
  );

  const onModalClose = () => {
    setSrc("");
  };

  if (!selectedMap || !agent) return null;

  const mapTips =
    (selectedMap?.key &&
      guidesList?.filter((tip) => tip.map === selectedMap.key)) ||
    [];
  const tipsCount = mapTips.length;
  const headshot = getAgentImage(agent.key, "matchtile");

  const lineupUrl = `/valorant/lineups/${selectedMap.key}?agent=${agent.key}`;
  const agentColor = AGENT_COLORS[agent.key];

  return (
    <Container
      style={{ "--agent-color": agentColor ? `hsl(${agentColor})` : null }}
    >
      <MapSelector
        agent={agent}
        maps={list}
        selectedMap={selectedMap}
        onChange={onMapChange}
      />
      <CardInner>
        <MapFrame>
          <img src={selectedMap.images?.layout} className="map-img" />
          {selectedMap.images?.labels?.attacking.length ? (
            <img
              src={selectedMap.images.labels.attacking}
              className="map-img"
            />
          ) : null}
          {mapTips.map((tip) => {
            return (
              <Dot
                key={tip.title}
                positionX={tip.location.icon.x}
                positionY={tip.location.icon.y}
                video={tip.video}
                difficulty={tip.difficulty}
                onClick={() => setSrc(getSrc(tip))}
              />
            );
          })}
        </MapFrame>
        <TipsLink href={lineupUrl}>
          {headshot && <img className="agent-headshot" src={headshot} />}
          <div>
            <span className="type-subtitle2">
              {t("val:agentMapLineup", "{{agent}} {{map}} Lineup", {
                agent: agent.name,
                map: selectedMap.name,
              })}
            </span>
            <span className="subtitle type-caption">
              <Trans i18nKey="val:atkDefTips">
                {{ tipsCount: tipsCount }} Attacking/Defending tips
              </Trans>
            </span>
          </div>
          <BlitzChevronRight />
        </TipsLink>
      </CardInner>
      <VideoModal
        onModalClose={onModalClose}
        src={src}
        showModal={src}
        isMuted={muted}
      />
    </Container>
  );
}
